import React from 'react';
import '../style.css';
import 'tailwindcss/dist/base.css';
import { Helmet } from 'gatsby-plugin-react-i18next';
import tw from 'twin.macro';
import styled from 'styled-components';
import AnimationRevealPage from '../../helpers/AnimationRevealPage';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import { SectionHeading } from '../../components/Misc/Headings';
import { Container, ContentWithPaddingXl } from '../../components/Misc/Layouts';
import signup from '../../images/tuto-signup.png';
import manage from '../../images/tuto-manage.png';
import search from '../../images/tuto-search.png';
import reports from '../../images/tuto-reports.png';

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default function AboutPage() {
    return (
        <>
            <Helmet>
                <title>Tutorial - How to start using Lunaar</title>
                <meta name="google-site-verification" content="rRfsfVDXLIju3OlVgucxioqo1riXyKuBauFgf0AgR3I" />
                <meta name="description" content="You don't know how to start your experience: this article is made for you." />
                <meta property="og:title" content="Tutorial - How to start using Lunaar" />
                <meta property="og:description" content="You don't know how to start your experience: this article is made for you." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://images.unsplash.com/photo-1592772356700-fe5accf6fae5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1346&q=80" />
                <meta property="og:image:url" content="https://images.unsplash.com/photo-1592772356700-fe5accf6fae5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1346&q=80" />
                <meta property="twitter:image" content="https://images.unsplash.com/photo-1592772356700-fe5accf6fae5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1346&q=80" />
            </Helmet>
            <AnimationRevealPage disabled>
                <Header />
                <Container>
                    <ContentWithPaddingXl>
                        <HeadingRow>
                            <Heading>Tutorial - How to start using Lunaar</Heading>
                        </HeadingRow>
                        <Text>
                            <p>Welcome to the Lunaar community!</p>
                            <p>You will find here the different steps to follow to start your experience with the application.</p>
                            <h1>1. Create your Account.</h1>
                            <p>If you are already logged in, please jump directly to step 2.</p>
                            <p>
                                <ul>
                                    <li>
                                        First, go to the application homepage that you can find
                                        {' '}
                                        <strong><a href="https://center.lunaar.app/" target="_blank">here</a></strong>
                                        .
                                    </li>
                                    <li>
                                        On the top right corner, start the authentication process by clicking the appropriate button.
                                    </li>
                                    <li>
                                        Switch to the
                                        {' '}
                                        <u>Sign Up</u>
                                        {' '}
                                        tab.
                                    </li>

                                    <p>
                                        <div style={{ textAlign: 'right' }}>
                                            <img src={signup} alt="" style={{ boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)', margin: '20px 0 10px 0', border: '1px solid lightgrey' }} />
                                            <span><i>Sign Up Form</i></span>
                                        </div>
                                    </p>

                                    <li>
                                        There are different ways to create your account: using social connectors and use your network profile or through the email/password form.
                                    </li>
                                </ul>
                            </p>
                            <p>Complete the flow and you will be redirected to the application homepage.</p>

                            <h1>2. Configure your Sources</h1>
                            <p>
                                From the header bar or the action
                                {' '}
                                <u>Follow</u>
                                {' '}
                                of the homepage, go to the
                                {' '}
                                <u>Sources</u>
                                {' '}
                                page.
                            </p>
                            <p>
                                You can also click
                                {' '}
                                <strong><a href="https://center.lunaar.app/sources" target="_blank">here</a></strong>
                                .
                            </p>

                            <p>
                                On the
                                {' '}
                                <u>Manage</u>
                                {' '}
                                tab you can unsubscribe from the sources you previously selected. If no source was added, go to the
                                {' '}
                                <u>Search</u>
                                {' '}
                                tab.
                            </p>
                            <p>
                                <div style={{ textAlign: 'right' }}>
                                    <img src={manage} alt="" style={{ boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)', margin: '20px 0 10px 0', border: '1px solid lightgrey' }} />
                                    <span><i>Followed Sources</i></span>
                                </div>
                            </p>
                            <p>
                                The search bar will help you to select already handled sources. You can select keywords and websites.
                            </p>
                            <p>They define your interest fields: it means what you want to watch.</p>
                            <p>The application is going to gather content related to these words and build a daily report just for you.</p>
                            <p>You are currently limited to 8 sources.</p>
                            <p>Groups of words (compounds or nominal groups) are allowed but less efficient: please replace all spaces by dashes (example: two-words).</p>
                            <p>This configuration is important, that's why you may be contacted to receive some pieces of advice about your sources if we see that the results are not as good as expected.</p>
                            <p>
                                <div style={{ textAlign: 'right' }}>
                                    <img src={search} alt="" style={{ boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)', margin: '20px 0 10px 0', border: '1px solid lightgrey' }} />
                                    <span><i>Search Interface</i></span>
                                </div>
                            </p>

                            <p>If the search doesn't provide any result, you can add your own keywords and websites.</p>
                            <p>You can provide URLs from any blog, any page or website you are expecting content from.</p>
                            <p>Pay attention to save precisely the URL of the page where content is published.</p>
                            <p>For each source, the application will collect new publications about it and add it to your reports.</p>

                            <h1>3. Configure your Reports.</h1>
                            <p>
                                From the header bar or the action
                                {' '}
                                <u>Configure</u>
                                {' '}
                                of the homepage, go to the
                                {' '}
                                <u>Settings</u>
                                {' '}
                                page.
                            </p>
                            <p>
                                You can also click
                                {' '}
                                <strong><a href="https://center.lunaar.app/settings" target="_blank">here</a></strong>
                                .
                            </p>
                            <p>You have now access to an important configuration tab.</p>
                            <p>Several parameters change the shape of your reports.</p>
                            <p>You can update the amount of resources suggested daily with the size parameter.</p>
                            <p>Resources will be mainly in English but it's possible to add some content from other languages: click on flags to enable or disable languages.</p>

                            <p>
                                <div style={{ textAlign: 'right' }}>
                                    <img src={reports} alt="" style={{ boxShadow: '0 .5rem 1rem rgba(0,0,0,.15)', margin: '20px 0 10px 0', border: '1px solid lightgrey' }} />
                                    <span><i>Reports Settings Menu</i></span>
                                </div>
                            </p>

                            <br />
                            <p>And that's all, the application is now correctly configured.</p>

                            <h1>4. Select Content from Suggested Resources.</h1>
                            <p>Your new settings will be taken into consideration for the next report generation. You may come back tomorrow to see your first suggestions.</p>
                            <p>The reports are not built to provide you a huge amount of resources every day. They are made to let you read the titles, save some links and explore at least a few ones.</p>

                            <h1>5. Browse and Discover!</h1>
                            <p>You are now able to master the application principle and to achieve an efficient watch work.</p>
                            <p>The objective is to discover new things that you can quickly use, to watch updates of precise and global technologies, etc.</p>
                            <p>Continuous learning, continuous improvements!</p>
                            <br />
                            <p>
                                For any other question please contact us at
                                <a href="mailto:julien.griffoul@griffoul.in">julien.griffoul@griffoul.in</a>
                                .
                            </p>
                        </Text>
                    </ContentWithPaddingXl>
                </Container>
                <Footer />
            </AnimationRevealPage>
        </>
    );
}
